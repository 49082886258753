import React from 'react';
import { useSignOut } from 'react-firebase-hooks/auth';
import { FaPlusCircle } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import auth from '../../firebase.init';
import Loading from '../Shared/Loading/Loading';
import EventRow from './EventRow';

const ManageEvents = () => {
    const [signOut] = useSignOut(auth);
    const navigate = useNavigate();
    //Loading events Array api
    const { isLoading, error, data: events, refetch } = useQuery('eventsData', () =>
        fetch(process.env.REACT_APP_API_URL+'/events').then(res =>
            res.json()
        )
    )
    if (isLoading) {
        return <Loading></Loading>
    }

    const handleRemoveEvent = _id => {
        let confirmation = window.confirm('Are you sure to delete the event?');
        if (confirmation) {
            //delete api
            fetch(process.env.REACT_APP_API_URL+`/event/${_id}`, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(res => {
                    if (res.status === 403 || res.status === 401) {
                        signOut();
                        localStorage.removeItem('accessToken');
                        navigate('/login');
                    }
                    return res.json();
                })
                .then(data => {
                    if (data.deletedCount === 1) {
                        toast.success("Deleted Successfully");
                        refetch();
                    }
                    else {
                        toast.error("Delete Failed");
                    }
                    return;
                })
        }
    }

    return (
        <div>
            <label htmlFor="my-drawer-2" className="btn btn-ghost drawer-button lg:hidden ml-1 basis-1/6">{'<< menu'}</label>
            <h2 className='font-semibold text-2xl text-center my-5'>Manage Events</h2>
            <button className="btn btn-primary text-white rounded"><Link to='/dashboard/addEvent' className='flex justify-items-center items-center'><FaPlusCircle className='mr-2' />Add Event</Link></button>
            <div className="overflow-x-auto w-10/12 mt-5 mx-auto border rounded-xl">
                <table className="table w-full">
                    <thead>
                        <tr>
                            <th className='bg-gray-300'>No.</th>
                            <th className='bg-gray-300'>Name</th>
                            <th className='bg-gray-300'>Date</th>
                            <th className='bg-gray-300'>Total Participants</th>
                            <th className='bg-gray-300'>Action 1</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            events.map((event, index) => <EventRow
                                key={event._id}
                                eventNo={index + 1}
                                refetch={refetch}
                                event={event}
                                handleRemoveEvent={handleRemoveEvent}
                            ></EventRow>)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ManageEvents;