import React from 'react';
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom';
import auth from '../../../firebase.init';
import Loading from '../Loading/Loading';

const Navbar = () => {
    const [user, loading, error] = useAuthState(auth);
    const [signOut] = useSignOut(auth);


    if (loading) {
        return <Loading></Loading>;
    }

    const logout = () => {
        signOut(auth);
        localStorage.removeItem('accessToken');
    };

    const menuItems = <>
        <li><Link to="/" className='rounded-md'>Home</Link></li>
        <li><Link to="/research" className='rounded-md'>Research</Link></li>
        <li><Link to="/peoples" className='rounded-md'>Peoples</Link></li>
        <li><Link to="/events" className='rounded-md'>Events</Link></li>
        <li><Link to="/contact" className='rounded-md'>Contact</Link></li>
        <li><Link to="/about" className='rounded-md'>About</Link></li>
        {user && <li><Link to="/dashboard" className='rounded-md'>Dashboard</Link></li>}
        {/* {user && <li className='rounded-md text-sm py-4'>({user.displayName})</li>}
        <li>{user ? <button className='rounded-md bg-red' onClick={logout}>SignOut</button> : <Link to="/login" className='rounded-md'>Login</Link>}</li> */}
    </>



    return (
        <div className="navbar bg-base-100 border">
            <div className="navbar-start">
                <div className="dropdown">
                    <label tabIndex={0} className="btn btn-ghost lg:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                    </label>
                    <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                        {menuItems}
                    </ul>
                </div>
                <Link to='/' className='text-2xl font-bold text-primary ml-5'>NLP LAB</Link>
            </div>
            {/* //here
            //here */}
            <div className="navbar-center hidden lg:flex">
                <ul className="menu menu-horizontal p-0">
                    {menuItems}
                </ul>
            </div>
            <div className="navbar-end">
                {/* <ul className="menu menu-horizontal p-0">
                    {
                        menuItems
                    }

                </ul> */}
                {user && <span className='mr-2'>{user.displayName}</span>}
                {user ? <button className="btn btn-ghost rounded-md" onClick={logout}><Link className='rounded-md bg-red'>SignOut</Link></button>
                    : <Link to="/login" className='rounded-md mr-5'>Login</Link>}
            </div>

        </div>
    );
};

export default Navbar;