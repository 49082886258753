import React from 'react';
import { useSignOut } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import auth from '../../firebase.init';
import Loading from '../Shared/Loading/Loading';
import ResearchComponent from './ResearchComponent';

const Research = () => {
    const [signOut] = useSignOut(auth);
    const navigate = useNavigate();
    //Loading events Array api
    const { isLoading, error, data: researches, refetch } = useQuery('researchesData', () =>
        fetch(process.env.REACT_APP_API_URL+'/research').then(res =>
            res.json()
        )
    )

    const handleRemoveResearch = _id => {
        let confirmation = window.confirm('Are you sure to delete the Research Paper?');
        if (confirmation) {
            //delete api
            fetch(process.env.REACT_APP_API_URL+`/research/${_id}`, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(res => {
                    if (res.status === 403 || res.status === 401) {
                        signOut();
                        localStorage.removeItem('accessToken');
                        navigate('/login');
                    }
                    return res.json();
                })
                .then(data => {
                    if (data.deletedCount === 1) {
                        toast.success("Deleted Successfully");
                        refetch();
                    }
                    else {
                        toast.error("Delete Failed");
                    }
                    return;
                })
        }
    }

    if (isLoading) {
        return <Loading></Loading>
    }
    return (
        <div>
            <h2 className='font-semibold text-2xl text-center my-10'>All Researches</h2>
            <div className='my-10 w-10/12 mx-auto'>
                {
                    researches?.map(research => <ResearchComponent key={research._id} research={research} handleRemoveResearch={handleRemoveResearch}></ResearchComponent>)
                }
            </div>
        </div>
    );
};

export default Research;