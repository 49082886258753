import React from 'react';
import { FaFacebookSquare, FaGithub } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer footer-center p-10 bg-base-200 text-base-content rounded mt-10">
            <div className="grid grid-flow-col gap-4">
                <Link to="/about" className='link link-hover'>About us</Link>
                <Link to="/contact" className='link link-hover'>Contact</Link>
                {/* <a className="link link-hover">Jobs</a>
                <a className="link link-hover">Press kit</a> */}
            </div>
            <div>
                <div className="grid grid-flow-col gap-4">
                <a href="https://www.facebook.com/diunlp" target="_blank" rel="noopener noreferrer"><FaFacebookSquare className="w-6 h-6 hover:text-gray-500"/></a>
                <a href="https://github.com/DIU-NLP-ML" target="_blank" rel="noopener noreferrer"><FaGithub className="w-6 h-6 hover:text-gray-500" /></a>
               </div>
            </div>
            <div>
                <p>Copyright © 2023 - All right reserved by DIU NLP Lab</p>
            </div>
        </footer>
    );
};

export default Footer;