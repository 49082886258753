import React from 'react';
import { useSignOut } from 'react-firebase-hooks/auth';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { FaPlusCircle } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import auth from '../../firebase.init';
import Loading from '../Shared/Loading/Loading';
import TestimonialRow from './TestimonialRow';

const ManageTestimonials = () => {
    const [signOut] = useSignOut(auth);
    const navigate = useNavigate();
    //Loading events Array api
    const { isLoading, error, data: testimonials, refetch } = useQuery('testimonialsData', () =>
        fetch(process.env.REACT_APP_API_URL+'/testimonials').then(res =>
            res.json()
        )
    )
    if (isLoading) {
        return <Loading></Loading>
    }
    const handleRemoveTestimonial = _id => {
        let confirmation = window.confirm('Are you sure to delete the event?');
        if (confirmation) {
            //delete api
            fetch(process.env.REACT_APP_API_URL+`/testimonial/${_id}`, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(res => {
                    if (res.status === 403 || res.status === 401) {
                        signOut();
                        localStorage.removeItem('accessToken');
                        navigate('/login');
                    }
                    return res.json();
                })
                .then(data => {
                    if (data.deletedCount === 1) {
                        toast.success("Deleted Successfully");
                        refetch();
                    }
                    else {
                        toast.error("Delete Failed");
                    }
                    return;
                })
        }
    }
    return (
        <div>
            {/* menu button for lower size screen */}
            <label htmlFor="my-drawer-2" className="btn btn-ghost drawer-button lg:hidden ml-1">{'<< menu'}</label>
            <h2 className='font-semibold text-2xl text-center my-5'>Manage Testimonials</h2>
            <button className="btn btn-primary text-white rounded"><Link to='/dashboard/addTestimonial' className='flex justify-items-center items-center'><FaPlusCircle className='mr-2' />Add Testimonials</Link></button>
            <div className="overflow-x-auto w-10/12 mt-5 mx-auto border rounded-xl">
                <table className="table w-full">
                    <thead>
                        <tr>
                            <th className='bg-gray-300'>No.</th>
                            <th className='bg-gray-300'>Name</th>
                            <th className='bg-gray-300'>Email</th>
                            <th className='bg-gray-300'>Testimonial</th>
                            <th className='bg-gray-300'>Action 1</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            testimonials.map((testimonialInfo, index) => <TestimonialRow
                                key={testimonialInfo._id}
                                testimonialInfoNo={index + 1}
                                refetch={refetch}
                                testimonialInfo={testimonialInfo}
                                handleRemoveTestimonial={handleRemoveTestimonial}
                            ></TestimonialRow>)
                        }
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default ManageTestimonials;