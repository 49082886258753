import { NavLink } from 'react-router-dom';
import { VscBrowser } from 'react-icons/vsc';
const NotFound = () => {
    return (
        <div className="flex h-[50vh] sm:h-[50vh] md:h-[50vh] container flex-col items-center justify-center px-6 mx-auto">
            <VscBrowser className="w-20 h-20 md:mt-8 text-gray-800" fill="currentColor" />
            <h1 className="text-8xl font-semibold text-gray-900">404</h1>
            <h3 className="text-xl font-semibold text-gray-900">Page Not Found!</h3>
            <p className="text-gray-700 mt-2">
                Check the address or goto
                <NavLink to="/" className="text-blue-500 font-bold hover:text-blue-600 border-indigo-600 ">
                    {' '}
                    home
                </NavLink>
            </p>
        </div>
    );
};

export default NotFound;
