import { signOut } from 'firebase/auth';
import { useSignOut } from "react-firebase-hooks/auth";
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Loading from '../Shared/Loading/Loading';
import People from './People';
import auth from '../../firebase.init';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Peoples = () => {

    const [signOut] = useSignOut(auth);
    const navigate = useNavigate();
    const [isFiltered, setIsFiltered] = useState(false);
    const [peoplesToShow, setPeoplesToShow] = useState([]);


    // const [peoples, setPeoples] = useState([]);

    // useEffect(() => {
    //     fetch(process.env.REACT_APP_API_URL+'/peoples')
    //         .then(res => res.json())
    //         .then(data => setPeoples(data))
    // }, [])


    //Loading Peoples Array api
    const { isLoading, error, data: peoples, refetch } = useQuery('peoplesData', () =>
        fetch(process.env.REACT_APP_API_URL + '/peoples').then(res =>
            res.json()
        )
    )

    // const [peoplesToShow, setPeoplesToShow] = useState(peoples);


    if (isLoading) {
        return <Loading></Loading>
    }



    //keep state of the people type filter
    const changePeopleType = e => {
        setIsFiltered(true);
        const peoplesType = e.target.value;
        if (peoplesType === "all") {
            refetch();
            setPeoplesToShow(peoples);
        }
        else {
            const filteredPeoples = peoples.filter(people => people.category === peoplesType);
            setPeoplesToShow(filteredPeoples);
        }

    }

    const handleRemovePeople = _id => {
        let confirmation = window.confirm('Are you sure to delete the person?');
        if (confirmation) {
            //delete api
            fetch(process.env.REACT_APP_API_URL + `/people/${_id}`, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(res => {
                    if (res.status === 403 || res.status === 401) {
                        signOut();
                        localStorage.removeItem('accessToken');
                        navigate('/login');
                    }
                    return res.json();
                })
                .then(data => {
                    if (data.deletedCount === 1) {
                        toast.success("Deleted Successfully");
                        refetch();
                    }
                    else {
                        toast.error("Delete Failed");
                    }
                    return;
                })
        }
    }


    return (
        <div className='w-4/5 mx-auto mt-10'>
            <h2 className='font-semibold text-2xl text-center'>All Peoples</h2>
            {/* select people category */}
            <div className="form-control w-full max-w-xs ml-auto">
                <label className="label">
                    {/* <span className="label-text text-sm">Filter</span> */}
                    {/* <span className="label-text-alt">Alt label</span> */}
                </label>
                <select onChange={changePeopleType} className="select select-sm select-bordered">
                    <option value='all'>All</option>
                    <option value="faculty">Faculty</option>
                    <option value="student">Student</option>
                </select>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 my-10'>
                {
                    isFiltered ? peoplesToShow?.map(people => <People key={people._id} people={people} handleRemovePeople={handleRemovePeople}></People>) : peoples?.map(people => <People key={people._id} people={people} handleRemovePeople={handleRemovePeople}></People>)
                }
            </div>
        </div>

    );
};

export default Peoples;