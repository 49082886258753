import React from 'react';

const EventRow = ({ event, eventNo, refetch, handleRemoveEvent }) => {
    const { _id, name, participants, date } = event;
    return (
        <tr>
            <th>{eventNo}</th>
            <td>{name}</td>
            <td>{date}</td>
            <td>{participants.length}</td>
            <td><button onClick={() => handleRemoveEvent(_id)} className="btn btn-xs btn-error">Delete Event</button></td>
        </tr>

    );
};

export default EventRow;