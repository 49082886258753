import React from 'react';

const TestimonialRow = ({ testimonialInfo, handleRemoveTestimonial, testimonialInfoNo, refetch }) => {
    const { _id, name, email, testimonial } = testimonialInfo;
    return (

        <tr>
            <th>{testimonialInfoNo}</th>
            <td>{name}</td>
            <td>{email}</td>
            <td>{testimonial}</td>
            <td><button onClick={() => handleRemoveTestimonial(_id)} className="btn btn-xs btn-error">Delete Testimonial</button></td>
        </tr>

    );
};

export default TestimonialRow;