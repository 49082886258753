import React from 'react';
import Footer from './Footer/Footer'
import Navbar from './Navbar/Navbar'
const Layout = (props) => {
    return (
        <div className='min-h-screen flex flex-col'>
            <Navbar></Navbar>
            <main className='relative flex-1'>
                {props.children}
            </main>
            <Footer className=""></Footer>
        </div>
    );
};

export default Layout;