import React from 'react';
import Loading from '../Shared/Loading/Loading';
import { useQuery } from 'react-query';
import StatsCard from './StatsCard';

const Stats = () => {
    //const navigate = useNavigate();
    const {
        isLoading,
        error,
        data: stats,
        refetch,
    } = useQuery('statsData', () =>
        fetch(process.env.REACT_APP_API_URL + '/stats', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
        }).then(
            (res) => res.json()
            //console.log( res.json())
        )
    );
    if (isLoading) {
        return <Loading />;
    }
    return (
        <div className="w-4/5 mx-auto mt-10">
            <h2 className="font-semibold text-2xl text-center">Stats</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-5 my-10">
                {stats.map((stat) => (
                    <StatsCard key={stat.id} id={stat.id} name={stat.name} value={stat.value} />
                ))}
            </div>
        </div>
    );
};

export default Stats;
