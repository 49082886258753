import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Navbar from './pages/Shared/Navbar/Navbar';
import Contact from './pages/Contact/Contact';
import Footer from './pages/Shared/Footer/Footer';
import Login from './pages/Login/Login';
import Peoples from './pages/Peoples/Peoples';
import Research from './pages/Research/Research';
import Events from './pages/Events/Events';
import RequireAuth from './pages/Login/RequireAuth';
import Dashboard from './pages/Dashboard/Dashboard';
import MyEvents from './pages/Dashboard/MyEvents';
import AddPeople from './pages/Dashboard/AddPeople';
import Users from './pages/Dashboard/Users';
import AddEvent from './pages/Dashboard/AddEvent';
import SignUp from './pages/Login/SignUp';
import RequireAdmin from './pages/Login/RequireAdmin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddResearch from './pages/Dashboard/AddResearch';
import Layout from './pages/Shared/Layout';
import ManageEvents from './pages/Dashboard/ManageEvents';
import ManageTestimonials from './pages/Dashboard/ManageTestimonials';
import AddTestimonial from './pages/Dashboard/AddTestimonial';
import Stats from './pages/Dashboard/Stats';
import NotFound from './pages/NotFound/NotFound';

function App() {
  return (
    <div>
      {/* <Navbar></Navbar> */}
      <Layout>
      <Routes>
        <Route path='/' element={<Home></Home>}></Route>
        <Route path='login' element={<Login></Login>}></Route>
        <Route path='signup' element={<SignUp></SignUp>}></Route>
        {/* dashboard route */}
        <Route path='dashboard' element={<RequireAuth><Dashboard></Dashboard></RequireAuth>}>
          <Route index element={<MyEvents></MyEvents>}></Route>
          <Route path='stats' element={<RequireAdmin><Stats/></RequireAdmin>}></Route>
          <Route path='addPeople' element={<RequireAdmin><AddPeople></AddPeople></RequireAdmin>}></Route>
          <Route path='users' element={<RequireAdmin><Users></Users></RequireAdmin>}></Route>
          <Route path='manageEvents' element={<RequireAdmin><ManageEvents></ManageEvents></RequireAdmin>}></Route>
          <Route path='addEvent' element={<RequireAdmin><AddEvent></AddEvent></RequireAdmin>}></Route>
          <Route path='addResearch' element={<RequireAdmin><AddResearch></AddResearch></RequireAdmin>}></Route>
          <Route path='addTestimonial' element={<RequireAdmin><AddTestimonial></AddTestimonial></RequireAdmin>}></Route>
          <Route path='manageTestimonials' element={<RequireAdmin><ManageTestimonials></ManageTestimonials></RequireAdmin>}></Route>
        </Route>
        <Route path='peoples' element={<Peoples></Peoples>}></Route>
        <Route path='research' element={<Research></Research>}></Route>
        <Route path='events' element={<Events></Events>}></Route>
        <Route path='about' element={<About></About>}></Route>
        <Route path='contact' element={<Contact></Contact>}></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* <Footer></Footer> */}
      <ToastContainer />
      </Layout>
    </div>
  );
}

export default App;
