import React from 'react';
import Events from '../Events/Events';
import Divider from '../Shared/Divider/Divider';
import Carousel from './Carousel';
import Testimonials from './Testimonials';

const Home = () => {

    return (
        <div>
            <Carousel></Carousel>
            {/* <Divider></Divider> */}
            <Divider></Divider>
            <Events page = 'home'></Events>
            <Divider></Divider>
            <Testimonials></Testimonials>
        </div>
    );
};

export default Home;