import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import useAdmin from '../../hooks/useAdmin';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AddResearchCard = (props) => {
    const [user] = useAuthState(auth);
    const [admin] = useAdmin(user);
    const navigate = useNavigate();
    //const { _id, title, authors, conference, paperLink } = research;
    const addPaperHandler = (props) => {
        const researchPaper = {
            title: props.title,
            authors: props.authors,
            conference: props.conference,
            paperLink: props.url,
        };

        fetch(process.env.REACT_APP_API_URL + `/research`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
            body: JSON.stringify(researchPaper),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.acknowledged === true) {
                    toast.success('New Research Paper Added');
                    navigate('/research');
                } else {
                    toast.error('Research Paper Add Failed');
                }
                return;
            });
    };
    return (
        <div className="card w-100 my-4 bg-zinc-100 border rounded-lg">
            <div className="card-body">
                <h2 className="card-title">{props.authors}</h2>
                <p className="font-semibold  text-primary">{props.title}</p>
                <p>Publisher: {props.conference}</p>
                
                    {/* <button className="btn btn-primary rounded-md btn-sm"> */}
                    <a href={props.url} target="_blank" rel="noopener noreferrer">
                        {props.url}
                    </a>
                    {/* </button>{' '} */}

                    <button onClick={() => addPaperHandler(props)} className="btn btn-primary btn-sm rounded-md mt-2">
                        Add Paper
                    </button>
               
            </div>
        </div>
    );
};

export default AddResearchCard;
