import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import 'react-day-picker/dist/style.css';


const AddEvent = () => {

    const navigate = useNavigate();
    const [date, setSelected] = useState(new Date());
    let calenderFooter = <p>Please pick a day.</p>;
    if (date) {
        calenderFooter = <p className='mt-5 text-lg font-semi-bold'>Selected Date: {format(date, 'PP')}.</p>;
    }

    const handleAddEvent = event => {

        event.preventDefault();
        const members = [];
        const Event = {
            name: event.target.name.value,
            venue: event.target.venue.value,
            speaker: event.target.speaker.value,
            participants: [],
            date: format(date, 'PP')

        }

        //Add event api

        fetch(process.env.REACT_APP_API_URL+`/event`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: JSON.stringify(Event)
        })
            .then(res => res.json())
            .then(data => {
                if (data.acknowledged === true) {
                    toast.success('New Event Added');
                    navigate('/events')

                }
                else {
                    toast.error('Event Add Failed');
                }
                return;
            })
    }

    return (
        <div className="">
            <label htmlFor="my-drawer-2" className="btn btn-ghost drawer-button lg:hidden ml-1 basis-1/6">{'<< menu'}</label>
            <h2 className='text-center text-2xl my-5'>Add An Event</h2>
            {/* <div className='flex flex-row mt-2'>
                <label htmlFor="my-drawer-2" className="btn btn-ghost drawer-button lg:hidden ml-1 basis-1/6">{'<< menu'}</label>
                <div className="title basis-5/6 my-auto">
                    <h2 className='text-center text-2xl'>Add An Event</h2>
                </div>
            </div> */}

            <div className="grid grid-cols-1 md:grid-cols-2">
                {/* //submit form */}
                <form onSubmit={handleAddEvent} className='grid grid-cols-1 gap-5 justify-items-center mt-6'>
                    <input type="text" name='name' placeholder='Event Name' required className="input  input-bordered  w-full max-w-xs" />
                    <input type="text" name="venue" placeholder='Venue' required className="input  input-bordered  w-full max-w-xs" />
                    <input type="text" name="speaker" placeholder='Speaker' required className="input input-bordered  w-full max-w-xs" />
                    <input type="submit" value="submit" className="btn btn-primary  w-full max-w-xs" />
                </form>
                <DayPicker

                    mode="single"
                    selected={date}
                    onSelect={setSelected}
                    footer={calenderFooter}

                />
            </div>
        </div>
    );
};

export default AddEvent;