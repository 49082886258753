import React from 'react';
import { Link } from 'react-router-dom';
import nlpImg from '../../assets/images/NLP.jpg';
const Carousel = () => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 gap-2 w-11/12 my-10 mx-auto'>
            <div className="carousel m-4">
                <div id="slide1" className="carousel-item relative w-full">
                    <img src={nlpImg} className="w-full" />
                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <a href="#slide4" className="btn btn-circle">❮</a>
                        <a href="#slide2" className="btn btn-circle">❯</a>
                    </div>
                </div>
                <div id="slide2" className="carousel-item relative w-full">
                    <img src={nlpImg} className="w-full" />
                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <a href="#slide1" className="btn btn-circle">❮</a>
                        <a href="#slide3" className="btn btn-circle">❯</a>
                    </div>
                </div>
                <div id="slide3" className="carousel-item relative w-full">
                    <img src={nlpImg} className="w-full" />
                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <a href="#slide2" className="btn btn-circle">❮</a>
                        <a href="#slide4" className="btn btn-circle">❯</a>
                    </div>
                </div>
                <div id="slide4" className="carousel-item relative w-full">
                    <img src={nlpImg} className="w-full" />
                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <a href="#slide3" className="btn btn-circle">❮</a>
                        <a href="#slide1" className="btn btn-circle">❯</a>
                    </div>
                </div>
            </div>
            <div className="m-4 flex justify-center items-center">
                <div className="">
                    <h3 className='text-2xl font-medium'>Welcome to</h3>
                    <h1 className='text-4xl font-bold text-primary'>Daffodil NLP Workshop</h1>
                    <h3 className='text-xl'>Bringing machine learning to the messes!</h3>
                    <p className='text-slate-600 my-3'>A consumable, programmable, and scalable Machine Learning platform that makes it easy to solve and automate Classification, Regression, Time Series Forecasting, Cluster Analysis, and other data-driven tasks.</p>
                    <Link to="/dashboard" className="btn btn-primary text-white my-1 rounded-lg">Get started</Link>
                </div>
            </div>
        </div>
    );
};

export default Carousel;