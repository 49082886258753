import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import useAdmin from '../../hooks/useAdmin';
import Loading from '../Shared/Loading/Loading';
import { CDN } from '../Shared/Utils';


const People = ({ people, handleRemovePeople }) => {

    const { _id, name, designation, email, imgUrl } = people;
    const [user] = useAuthState(auth);
    const [admin] = useAdmin(user);
    const cdnImgUrl = CDN(imgUrl);
    return (
        <div className="card bg-base-100 shadow-xl rounded-lg">
            <figure className="px-6 pt-6">
                <img src={cdnImgUrl} alt={name +' profile image'} className="w-11/12 rounded-xl aspect-square" />
            </figure>
            <div className="card-body items-center text-center px-2 pt-3 pb-5">
                <h2 className="card-title text-base">{name}</h2>
                <p className='text-sm'><span className='font-semibold'>Designation: </span>{designation}</p>
                <p className='text-sm'><span className='font-semibold'>Email: </span>{email}</p>
                <div className="card-actions">

                    {admin && <button onClick={() => handleRemovePeople(_id)} className="btn btn-outline btn-error btn-sm rounded-md mt-2">Delete This User</button>}
                </div>
            </div>
        </div>
    );
};

export default People;