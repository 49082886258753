import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddPeople = () => {
    const facultyUrlRef = useRef();
    const navigate = useNavigate();
    const imageStorageKey = 'd4c48b76823c8b9ae6e5a0fae7a5d3a9';

    const [show, setShow] = useState(false);

    const [showAddFields, setShowAddFields] = useState(false);

    const [nameState, setNameState] = useState('');
    const [emailState, setEmailState] = useState('');
    const [designationState, setDesignationState] = useState('');
    const [webState, setWebState] = useState('');
    const [facultyImgUrl, setFacultyImgUrl] = useState('');

    const handleSetShowFaculty = () => {
        setShow(true);
    };

    const handleSetShowStudent = () => {
        setShow(false);
    };

    const handleFacultyInfoScraper = (ele) => {
        if (ele.target.value == 'faculty') {
            handleSetShowFaculty();
            setShowAddFields(true);
        } else if (ele.target.value == 'student') {
            handleSetShowStudent();
            setShowAddFields(true);
        }
    };

    const handleAddPeople = (event) => {
        event.preventDefault();
        if (!facultyImgUrl) {
            // console.log(facultyImgUrl);
            const url = `https://api.imgbb.com/1/upload?key=${imageStorageKey}`;
            const image = event.target.imgUrl.files[0];
            const formData = new FormData();
            formData.append('image', image);

            fetch(url, {
                method: 'POST',
                body: formData,
            })
                .then((res) => res.json())
                .then((result) => {
                    if (result.success) {
                        const people = {
                            name: event.target.name.value,
                            email: event.target.email.value,
                            designation: event.target.designation.value,
                            imgUrl: result.data.url,
                            website: event.target.website.value,
                            linkedin: event.target.linkedin.value,
                            facebook: event.target.facebook.value,
                            github: event.target.github.value,
                            category: event.target.category.value,
                        };
                        //sending data to the database
                        fetch(process.env.REACT_APP_API_URL + `/people`, {
                            method: 'POST',
                            headers: {
                                'content-type': 'application/json',
                                authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                            },
                            body: JSON.stringify(people),
                        })
                            .then((res) => res.json())
                            .then((data) => {
                                if (data.acknowledged === true) {
                                    toast.success('New People Added');
                                    navigate('/peoples');
                                } else {
                                    toast.error('People Add Failed');
                                }
                                return;
                            });
                    }
                });
        }

        // uplaod img from url
        else if (facultyImgUrl) {
            const people = {
                name: event.target.name.value,
                email: event.target.email.value,
                designation: event.target.designation.value,
                imgUrl: facultyImgUrl,
                website: event.target.website.value,
                linkedin: event.target.linkedin.value,
                facebook: event.target.facebook.value,
                github: event.target.github.value,
                category: event.target.category.value,
            };
            //sending data to the database
            fetch(process.env.REACT_APP_API_URL + `/people`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify(people),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.acknowledged === true) {
                        toast.success('New People Added');
                        navigate('/peoples');
                    } else {
                        toast.error('People Add Failed');
                    }
                    return;
                });
        }
    };
    const facultyScrapHandler = () => {
        const facultyUrl = facultyUrlRef.current.value;
        if (!facultyUrl) {
            toast.error("Faculty Scrapper Link Can't Left Empty");
            return;
        } else {
            let pattern = /^(http|https):\/\/faculty\.daffodilvarsity\.edu\.bd?(.*)/;
            let result = pattern.test(facultyUrl);
            if (!result) {
                toast.error('Enter a Valid URL for Faculty Member');
                return;
            }
        }
        const reqBody = {
            facultyUrl: facultyUrl,
        };
        fetch(process.env.REACT_APP_API_URL + `/people/scrap`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
            body: JSON.stringify(reqBody),
        })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data);
                if (data['name']) {
                    setNameState(data['name']);
                    setEmailState(data['email']);
                    setDesignationState(data['designation'] + ', ' + data['department'] + ', ' + data['faculty']);
                    setWebState(facultyUrl);
                    setFacultyImgUrl(data['profileImgUrl']);
                    toast.success('Faculty Data Scaped');
                } else {
                    toast.error('Faculty Data Scaping Failed');
                }
                return;
            });
    };

    return (
        <div className="">
            <div className="flex flex-row">
                <label htmlFor="my-drawer-2" className="btn btn-ghost drawer-button lg:hidden ml-1 basis-1/6">
                    {'<< menu'}
                </label>
                <div className="title basis-5/6 my-auto">
                    {/* //Page title */}
                    <h2 className="text-center text-2xl font-semibold my-3">Add A Member</h2>
                    {/* //submit form */}
                    <form onSubmit={handleAddPeople} className="grid grid-cols-1 gap-5 justify-items-center mt-2">
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                                {/* <span className="label-text text-sm">Filter</span> */}
                                {/* <span className="label-text-alt">Alt label</span> */}
                            </label>
                            <select name="category" className="select select-sm select-bordered" defaultValue={'memberType'} onChange={handleFacultyInfoScraper}>
                                <option value="memberType" disabled defaultValue>
                                    Member Type
                                </option>
                                <option value="faculty">Faculty</option>
                                <option value="student">Student</option>
                            </select>
                        </div>
                        {showAddFields && (
                            <>
                                {show && (
                                    <>
                                        <input type="text" name="facultyWebPage" ref={facultyUrlRef} placeholder="Faculty Web Page Link" required className="input input-sm input-bordered  w-full max-w-xs" />
                                        <input type="button" value="Scrap" onClick={facultyScrapHandler} className="btn btn-accent rounded btn-sm w-full max-w-xs text-white" />{' '}
                                    </>
                                )}

                                <input type="text" name="name" defaultValue={nameState} placeholder="Full Name" required className="input input-sm input-bordered  w-full max-w-xs" />
                                <input type="email" name="email" defaultValue={emailState} placeholder="Email" required className="input input-sm input-bordered  w-full max-w-xs" />
                                <input type="text" name="designation" defaultValue={designationState} placeholder="Designation" required className="input input-sm input-bordered  w-full max-w-xs" />
                                {/* <input type="text" name="imgUrl" placeholder='Profile Image Url' required className="input input-bordered  w-full max-w-xs" /> */}
                                <input type="text" name="website" defaultValue={webState} placeholder="Website Link" required className="input input-sm input-bordered  w-full max-w-xs" />
                                <input type="text" name="linkedin" defaultValue="" placeholder="LinkedIn Link" className="input input-sm input-bordered  w-full max-w-xs" />
                                <input type="text" name="github" defaultValue="" placeholder="Github Link" className="input input-sm input-bordered  w-full max-w-xs" />
                                <input type="text" name="facebook" defaultValue="" placeholder="Facebook Link" className="input input-sm input-bordered  w-full max-w-xs" />

                                {!show && (
                                    <div className="form-control w-full max-w-xs">
                                        <label className="label">
                                            <span className="label-text">Upload Profile Image</span>
                                        </label>
                                        <input type="file" name="imgUrl" className="file-input file-input-bordered file-input-primary file-input-sm w-full max-w-xs" />
                                    </div>
                                )}

                                <input type="submit" value="submit" className="btn btn-primary rounded btn-sm w-full max-w-xs" />
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddPeople;
