import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddTestimonial = () => {

    const navigate = useNavigate();
    const imageStorageKey = 'd4c48b76823c8b9ae6e5a0fae7a5d3a9';

    const handleAddTestimonial = event => {
        event.preventDefault();
        const url = `https://api.imgbb.com/1/upload?key=${imageStorageKey}`;
        const image = event.target.imgUrl.files[0];
        const formData = new FormData();
        formData.append('image', image);

        fetch(url, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(result => {
                if (result.success) {
                    const testimonial = {
                        name: event.target.name.value,
                        email: event.target.email.value,
                        testimonial: event.target.testimonial.value,
                        imgUrl: result.data.url,
                        // website: event.target.website.value,
                        // linkedin: event.target.linkedin.value,
                        // facebook: event.target.facebook.value,
                        // github: event.target.github.value
                    }
                    //sending data to the database
                    fetch(process.env.REACT_APP_API_URL+`/testimonial`, {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json',
                            'authorization': `Bearer ${localStorage.getItem('accessToken')}`
                        },
                        body: JSON.stringify(testimonial)
                    })
                        .then(res => res.json())
                        .then(data => {
                            if (data.acknowledged === true) {
                                toast.success('New Testimonial Added');
                                navigate('/')

                            }
                            else {
                                toast.error('Testimonial Add Failed');
                            }
                            return;
                        })
                }
            })

    }
    return (
        <div className="">
            <label htmlFor="my-drawer-2" className="btn btn-ghost drawer-button lg:hidden ml-1 basis-1/6">{'<< menu'}</label>
            <div className='flex flex-row'>
                <div className="title basis-5/6 my-auto">
                    {/* //Page title */}
                    <h2 className='text-center text-2xl font-semibold my-3'>Add A Testimonial</h2>
                    {/* //submit form */}
                    <form onSubmit={handleAddTestimonial} className='grid grid-cols-1 gap-5 justify-items-center mt-2'>
                        <input type="text" name='name' placeholder='Full Name of Author' required className="input input-sm input-bordered  w-full max-w-xs" />
                        <input type="email" name='email' placeholder='Email' required className="input input-sm input-bordered  w-full max-w-xs" />
                        <input type="text" name="testimonial" placeholder='Testimonial' required className="input input-sm input-bordered  w-full max-w-xs" />
                        {/* <input type="text" name="imgUrl" placeholder='Profile Image Url' required className="input input-bordered  w-full max-w-xs" /> */}
                        {/* <input type="text" name="website" placeholder='Website Link' required className="input input-sm input-bordered  w-full max-w-xs" />
                        <input type="text" name="linkedin" placeholder='LinkedIn Link' required className="input input-sm input-bordered  w-full max-w-xs" />
                        <input type="text" name="github" placeholder='Github Link' required className="input input-sm input-bordered  w-full max-w-xs" />
                        <input type="text" name="facebook" placeholder='Facebook Link' required className="input input-sm input-bordered  w-full max-w-xs" /> */}
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                                <span className="label-text">Upload Profile Image</span>
                            </label>
                            <input type="file" name='imgUrl' required className="file-input file-input-bordered file-input-primary file-input-sm w-full max-w-xs" />
                        </div>

                        <input type="submit" value="submit" className="btn btn-primary rounded btn-sm w-full max-w-xs" />
                    </form>

                </div>
            </div>
        </div>
    );
};

export default AddTestimonial;