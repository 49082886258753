import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
// import useAdmin from '../../hooks/useAdmin';

const Event = ({ event, handleRemoveEvent, handleJoinEvent, showButton }) => {
    const { date, name, venue, speaker, participants } = event;
    const [user] = useAuthState(auth);
    // const [admin] = useAdmin(user);
    const isJoined = participants?.includes(user?.email);
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 rounded-lg border-2 my-3">
            <div className="date-div flex justify-items-center items-center bg-base-200 p-6">
                <h3 className='mx-auto text-xl font-bold'>Date: {date}</h3>
            </div>
            <div className="details-div p-6 flex flex-col justify-between">
                <div className="">
                    <h2><span className='text-xl font-bold text-primary'>{name}</span></h2>
                    <h3><span className='font-semibold text-lg'>Venue: </span>{venue}</h3>
                    <h3><span className='font-semibold text-lg'>Speaker: </span>{speaker}</h3>
                </div>
                <div className="">

                    {user?.email && showButton && <>
                    
                        {isJoined ? <button disabled className="btn btn-success btn-sm rounded-md mt-2 mr-2">Already joined</button> : <button onClick={() => handleJoinEvent(event, user.email)} className="btn btn-outline btn-success btn-sm rounded-md mt-2 mr-2">Join This Event</button>}
                    </>
                    }
                    {/* {showButton && user && admin && <button onClick={() => handleRemoveEvent(_id)} className="btn  btn-outline btn-error btn-sm rounded-md mt-2">Delete This Event</button>} */}
                </div>
            </div>
        </div>

    );
};

export default Event;