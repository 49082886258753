import React from 'react';
import { CDN } from '../Shared/Utils';

const IMG_URL = "https://i.ibb.co/PmwFxXS/099fb9bde6.jpg";
const cdnImgUrl = CDN(IMG_URL);

const About = () => {
    return (
        <div className="py-16 bg-white dark:bg-darkBg">
            <div className="container m-auto px-6 text-gray-600 md:px-12  xl:px-6 dark:bg-darkBg">
                <div className="space-y-6 md:space-y-0 lg:flex md:gap-6 lg:items-center lg:gap-12">
                    <div className="md:5/12 lg:w-5/12 md:pb-6 lg:pb-0 ">
                        <img
                            src={cdnImgUrl}
                            alt=""
                            loading="lazy" className="rounded-lg"
                        />
                    </div>
                    <div className="md:7/12 lg:w-6/12">
                        <h2 className="text-2xl text-gray-900 font-bold md:text-4xl dark:text-darkText">
                            NLP LAB
                        </h2>
                        <h2 className="text-2xl text-gray-900 font-bold md:text-4xl dark:text-darkText">
                            Daffodil International University
                        </h2>
                        <p className="mt-6 text-gray-600 dark:text-darkText2">
                            The Daffodil International University CSE Natural Language Processing and Machine Learning Research LAB group studies a range of core NLP problems (such as parsing, information extraction, and machine translation) as well as emerging challenges (such as modeling and processing social media text, analyzing linguistic style, and jointly modeling language and vision). It also discuss about data science and machine learning related problem solutions. Our research is highly collaborative: we regularly work with researchers across the globe including many other computer scientists, computational linguists, speech researchers, data and social scientists.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;