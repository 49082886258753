import React from 'react';

const Loading = () => {
    return (
        <div className='text-center'>
            <h3 className='font-bold'>Loading...</h3>
            <progress className="progress w-56"></progress>
        </div>

    );
};

export default Loading;