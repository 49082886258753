import { signOut } from "firebase/auth";
import { useEffect, useState } from "react"
import { useSignOut } from "react-firebase-hooks/auth";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import auth from "../firebase.init";

const useAdmin = user => {

    const [admin, setAdmin] = useState(false);
    const [adminLoading, setAdminLoading] = useState(true);
    const [signOut, loading, error] = useSignOut(auth);
    const navigate = useNavigate();

    useEffect(() => {
        const email = user?.email;
        if (email) {

            fetch(process.env.REACT_APP_API_URL+`/admin/${email}`, {
                method: "GET",
                headers: {
                    'content-type': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(res => {
                    if (res.status === 403 || res.status === 401) {
                        signOut();
                        localStorage.removeItem('accessToken');
                        navigate('/login');
                    }
                    return res.json();
                })
                .then(data => {
                    setAdmin(data.isAdmin);
                    setAdminLoading(false);
                    return;
                })
        }

    }, [user, navigate, signOut])
    return [admin, adminLoading];
}

export default useAdmin;