import React, { useEffect, useState } from 'react';
import { useSignOut } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import auth from '../../firebase.init';
import Loading from '../Shared/Loading/Loading';
import Event from './Event';
import { format } from 'date-fns';

const Events = (props) => {
    const [signOut] = useSignOut(auth);
    const navigate = useNavigate();

    //Loading events Array api
    const { isLoading, error, data: events, refetch } = useQuery('eventsData', () => fetch(process.env.REACT_APP_API_URL + '/events').then((res) => res.json()));

    const upcomingEvents = [];
    const pastEvents = [];
    const tmp = new Date();
    const today = new Date(format(tmp, 'PP'))
    // console.log(test);

    
    
    // console.log(format(today, 'PP'));
    // console.log(test);
    const handleJoinEvent = (joiningEvent, userEmail) => {
        const confirmation = window.confirm('Are you sure to join this event?');
        if (confirmation) {
            const oldParticipants = joiningEvent.participants;
            joiningEvent.participants = [...oldParticipants, userEmail];

            fetch(process.env.REACT_APP_API_URL + `/event/join`, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify(joiningEvent),
            })
                .then((res) => {
                    if (res.status === 403 || res.status === 401) {
                        signOut();
                        localStorage.removeItem('accessToken');
                        navigate('/login');
                    }
                    return res.json();
                })
                .then((data) => {
                    if (data.modifiedCount > 0) {
                        refetch();
                        toast.success(`event joined successfully`);
                    }
                });
        }
        return;
    };

    // const handleRemoveEvent = _id => {
    //     let confirmation = window.confirm('Are you sure to delete the event?');
    //     if (confirmation) {
    //         //delete api
    //         fetch(process.env.REACT_APP_API_URL+`/event/${_id}`, {
    //             method: 'DELETE',
    //             headers: {
    //                 'content-type': 'application/json',
    //                 'authorization': `Bearer ${localStorage.getItem('accessToken')}`
    //             }
    //         })
    //             .then(res => {
    //                 if (res.status === 403 || res.status === 401) {
    //                     signOut();
    //                     localStorage.removeItem('accessToken');
    //                     navigate('/login');
    //                 }
    //                 return res.json();
    //             })
    //             .then(data => {
    //                 if (data.deletedCount === 1) {
    //                     toast.success("Deleted Successfully");
    //                     refetch();
    //                 }
    //                 else {
    //                     toast.error("Delete Failed");
    //                 }
    //                 return;
    //             })
    //     }
    // }

    if (isLoading) {
        return <Loading></Loading>;
    }
    else {
        events.map((event) => { if (today > (new Date(event.date))) pastEvents.push(event); else upcomingEvents.push(event)})
        // console.log(pastEvents)
    }
    // console.log(props.page)
    return (
        <>
        
            <div className="mt-10">
                <h2 className="font-semibold text-2xl text-center">Upcoming Events</h2>
                
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-10 w-10/12 mx-auto">
                    {
                        // events?.map(event => <Event key={event._id} showButton={true} event={event} handleJoinEvent={handleJoinEvent} handleRemoveEvent={handleRemoveEvent}></Event>)
                        upcomingEvents?.map((event) => (
                            <Event key={event._id} showButton={true} event={event} handleJoinEvent={handleJoinEvent}></Event>
                        ))
                        
                    }
                    
                </div>
            </div>
            
            {props.page !== 'home' &&
            <div className="mt-10">
                <h2 className="font-semibold text-2xl text-center">Past Events</h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 my-10 w-10/12 mx-auto">
                    {
                        // events?.map(event => <Event key={event._id} showButton={true} event={event} handleJoinEvent={handleJoinEvent} handleRemoveEvent={handleRemoveEvent}></Event>)
                        pastEvents?.map((event) => (
                            <Event key={event._id} showButton={false} event={event} past="true" handleJoinEvent={handleJoinEvent}></Event>
                        ))
                    }
                </div>
            </div>}
        </>
    );
};

export default Events;
