import React from 'react';
import { CDN } from '../Shared/Utils';

const Testimonial = ({ testimonialInfo }) => {
    const { name, email, testimonial, imgUrl } = testimonialInfo;
    const cdnImgUrl = CDN(imgUrl);
    return (
        <div className="card w-65 glass">
            <figure className='py-3'>
                <div className="avatar">
                    <div className="w-32 rounded-full">
                        <img src={cdnImgUrl}/>
                    </div>
                </div>
            </figure>
            <div className="card-body p-2 text-center">
                <h2 className='text-xl text-primary font-semibold'>❝ {testimonial} ❞</h2>
                <p className="">- {name}</p>
                <div className="card-actions justify-end">
                    {/* <button className="btn btn-primary">Learn now!</button> */}
                </div>
            </div>
        </div>
    );
};

export default Testimonial;