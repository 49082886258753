import React from 'react';

const StatsCard = (props) => {
    return (
        <div className="card w-60 bg-base-100 border m-4 shadow-xl">
            <div className="card-body">
                <h2 className="card-title justify-center">{props.value}</h2>
                <p className="justify-center text-center">{props.name}</p>
            </div>
        </div>
    );
};

export default StatsCard;
