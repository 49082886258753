import React from 'react';
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import auth from '../../firebase.init';
import Event from '../Events/Event';
import Loading from '../Shared/Loading/Loading';

const MyEvents = () => {
    const [user] = useAuthState(auth);
    const [signOut] = useSignOut(auth);
    const navigate = useNavigate();
    //Loading events Array api
    const { isLoading, error, data: events, refetch } = useQuery('eventsData', () =>
        fetch(process.env.REACT_APP_API_URL+'/events').then(res =>
            res.json()
        )
    )

    const handleJoinEvent = (joiningEvent, userEmail) => {
        const oldParticipants = joiningEvent.participants;
        joiningEvent.participants = [...oldParticipants, userEmail];

        fetch(process.env.REACT_APP_API_URL+`/event/join`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: JSON.stringify(joiningEvent)
        })
            .then(res => {

                if (res.status === 403 || res.status === 401) {
                    signOut();
                    localStorage.removeItem('accessToken');
                    navigate('/login');
                }
                return res.json();
            })
            .then(data => {
                if (data.modifiedCount > 0) {
                    refetch();
                    toast.success(`event joined successfully`);
                }

            })
    }


    const handleRemoveEvent = _id => {
        let confirmation = window.confirm('Are you sure to delete the event?');
        if (confirmation) {
            //delete api
            fetch(process.env.REACT_APP_API_URL+`/event/${_id}`, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(res => {
                    if (res.status === 403 || res.status === 401) {
                        signOut();
                        localStorage.removeItem('accessToken');
                        navigate('/login');
                    }
                    return res.json();
                })
                .then(data => {
                    if (data.deletedCount === 1) {
                        toast.success("Deleted Successfully");
                        refetch();
                    }
                    else {
                        toast.error("Delete Failed");
                    }
                    return;
                })
        }
    }

    if (isLoading) {
        return <Loading></Loading>
    }



    const myEvents = events?.filter(event => event.participants.includes(user?.email));


    return (
        <div className='mt-10'>
            <label htmlFor="my-drawer-2" className="btn btn-ghost drawer-button lg:hidden ml-1 basis-1/6">{'<< menu'}</label>
            <h2 className='font-semibold text-2xl text-center'>My Joined Events ({myEvents.length})</h2>
            <div className='my-10 w-10/12 mx-auto'>
                {
                    myEvents.map(event => <Event key={event._id} showButton={false} event={event} handleJoinEvent={handleJoinEvent} handleRemoveEvent={handleRemoveEvent}></Event>)
                }
            </div>
        </div>
    );
};

export default MyEvents;