import React from 'react';
import { useSignOut } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import auth from '../../firebase.init';
import Loading from '../Shared/Loading/Loading';
import UserRow from './UserRow';
import { IoIosArrowBack } from "react-icons/io";
import { toast } from 'react-toastify';

const Users = () => {
    const navigate = useNavigate();
    const [signOut, loading] = useSignOut(auth);

    const { data: users, isLoading, error, refetch } = useQuery('users', () => fetch(process.env.REACT_APP_API_URL+'/user', {
        method: 'GET',
        headers: {
            'authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
    }).then(res => {
        if (res.status === 401 || res.status === 403) {
            navigate('/');
            signOut();
            localStorage.removeItem("accessToken");

        }
        return res.json();
    }));
    const handleRemoveUser = _id => {
        let confirmation = window.confirm('Are you sure to delete the user?');
        if (confirmation) {
            //delete api
            fetch(`${process.env.REACT_APP_API_URL}/user/${_id}`, {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    'authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(res => {
                    if (res.status === 403 || res.status === 401) {
                        signOut();
                        localStorage.removeItem('accessToken');
                        navigate('/login');
                    }
                    return res.json();
                })
                .then(data => {
                    if (data.deletedCount === 1) {
                        toast.success("Deleted Successfully");
                        refetch();
                    }
                    else {
                        toast.error("Delete Failed");
                    }
                    return;
                })
        }
    }

    if (isLoading) {
        return <Loading></Loading>
    }
    return (
        <div className="">
            <div className='flex flex-row mt-5'>
                <label htmlFor="my-drawer-2" className="btn btn-ghost drawer-button lg:hidden ml-1 basis-1/6"><IoIosArrowBack/>{'menu'}</label>
                <div className="title basis-5/6 my-auto"> <h2 className='text-center text-2xl font-semibold'>All Users</h2></div>
            </div>

            {/* Users Table */}
            <div className="overflow-x-auto w-10/12 mt-5 mx-auto border rounded-xl">
                <table className="table w-full">
                    <thead>
                        <tr>
                            <th className='bg-gray-300'>No.</th>
                            <th className='bg-gray-300'>Email</th>
                            <th className='bg-gray-300'>Option 1</th>
                            <th className='bg-gray-300'>Option 2</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users.map((user, index) => <UserRow
                                key={user._id}
                                userNo={index + 1}
                                refetch={refetch}
                                user={user}
                                handleRemoveUser={handleRemoveUser}
                            ></UserRow>)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Users;