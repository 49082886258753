import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, Outlet } from 'react-router-dom';
import auth from '../../firebase.init';
import useAdmin from '../../hooks/useAdmin';
import Loading from '../Shared/Loading/Loading';
import { BiCalendarEvent } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { IoPersonAddOutline, IoCreateOutline } from "react-icons/io5";
import { AiOutlineFileAdd } from "react-icons/ai"
import { FaPen, FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { BiDetail } from "react-icons/bi";

const Dashboard = () => {
    const [user, loading] = useAuthState(auth);
    const [admin, adminLoading] = useAdmin(user);

    if (loading || adminLoading) {
        return <Loading></Loading>
    }

    return (
        <div className="drawer drawer-mobile">
            <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content">
                {/* <!-- Page content here --> */}
                {/* <label htmlFor="my-drawer-2" className="btn btn-ghost drawer-button lg:hidden ml-1">{'<< menu'}</label> */}
                <Outlet></Outlet>
            </div>
            <div className="drawer-side">
                <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
                <ul className="menu p-4 w-80 bg-base-100 text-base-content">
                    {/* <!-- Sidebar content here --> */}
                    <li><Link to='/dashboard'><BiCalendarEvent />My Events</Link></li>
                    {
                        admin && <>
                            <li><Link to='/dashboard/stats'><BiDetail />Stats</Link></li>
                            <li><Link to='/dashboard/users'><FiUsers />Manage Users</Link></li>
                            <li><Link to='/dashboard/manageEvents'> <BiCalendarEvent />Manage Events</Link></li>
                            <li><Link to='/dashboard/manageTestimonials'> <FaPen className='text-xs' />Manage Testimonials</Link></li>
                            <li><Link to='/dashboard/addPeople'><IoPersonAddOutline />Add People</Link></li>
                            <li><Link to='/dashboard/addEvent'> <IoCreateOutline />Add Event</Link></li>
                            <li><Link to='/dashboard/addResearch'><AiOutlineFileAdd />Add Research</Link></li>
                        </>
                    }


                </ul>

            </div>
        </div>
    );
};

export default Dashboard;