import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddResearchCard from './AddResearchCard';

//https://api.crossref.org/works?rows=5&query=A Comprehensive Text Analysis for Bengali TTS using Unicode
////https://api.crossref.org//works?query.title=room+at+the+bottom&query.author=richard+feynman
//https://api.crossref.org/works?&query.author=Sheak%20Rashed%20Haider%20Noori

//const CROSS_REF_API = 'https://api.crossref.org/works?rows=';
const CROSS_REF_API = 'https://api.crossref.org/works?';
const USE_ROWS = false;
const RESULTS = 5;


const AddResearch = () => {
    const [paperData, setPaperData] = useState(false);
    const paperTitleDataRef = useRef();
    const paperAuthorDataRef = useRef();
    const navigate = useNavigate();
    const [searchResult, setSearchResult] = useState([]);
    const [loading, setLoading] = useState(false);

    const resetSearchHandler = () => {
        setPaperData(false);
        setSearchResult([]);
    };

    const handleAddResearch = (e) => {
        setLoading(true);
        e.preventDefault();
        let paperSearchTitle = paperTitleDataRef.current.value.replace(/\s+/g, ' ').trim();
        let paperSearchAuthor = paperAuthorDataRef.current.value.replace(/\s+/g, ' ').trim();
        if (!paperSearchTitle && !paperSearchAuthor) {
            toast.warn('Blank Search Query');
            setLoading(false);
            return;
        }
        let api_end = CROSS_REF_API;
        //if (paperSearchTitle) api_end = api_end + 'query.title=' + paperSearchTitle;
        //if (paperSearchAuthor) api_end = api_end + '&query.author=' + paperSearchAuthor;
        //console.log('research Paper', paperSearchTitle);
        //Add people api
        //let response = await fetch(CROSS_REF_API);
        let apiPara = {}
        if(USE_ROWS) apiPara.rows=RESULTS;
        if (paperSearchTitle) apiPara['query.title']=paperSearchTitle;
        if (paperSearchAuthor) apiPara['query.author']=paperSearchAuthor;

        fetch(api_end+ new URLSearchParams(apiPara))
            .then((res) => res.json())
            .then((data) => {
                if (data.status == 'ok') {
                    let __id = 0;
                    for (let i of data.message.items) {
                        setPaperData(true);
                        //console.log(i);
                        let entry = {
                            id: __id,
                            title: i.title[0],
                            //authors: i.author.join(','),
                            authors: i.author.map((u) => u.given + ' ' + u.family).join(', '),

                            conference: i.publisher,
                            url: i.URL,
                        };
                        __id = __id + 1;
                        setSearchResult((searchResult) => [...searchResult, entry]);
                    }
                    if (__id == 0) toast.warning('No results found!');
                    else toast.success('Results Fetched');
                } else toast.error('Something went wrong!');
                //console.log(searchResult);
                setLoading(false);
            });
        // add booking api
        // fetch(process.env.REACT_APP_API_URL + `/research`, {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'application/json',
        //         authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        //     },
        //     body: JSON.stringify(researchPaper),
        // })
        //     .then((res) => res.json())
        //     .then((data) => {
        //         if (data.acknowledged === true) {
        //             toast.success('New Research Paper Added');
        //             navigate('/research');
        //         } else {
        //             toast.error('Research Paper Add Failed');
        //         }
        //         return;
        //     });
    };

    return (
        <div className="w-4/5 mx-auto mt-10">
            <label htmlFor="my-drawer-2" className="btn btn-ghost drawer-button lg:hidden ml-1 basis-1/6">
                {'<< menu'}
            </label>
            <h2 className="text-center text-2xl font-semibold my-10">Add Research Paper</h2>
            {paperData && (
                <button onClick={resetSearchHandler} className="btn btn-primary btn-sm rounded-md">
                    Search Another
                </button>
            )}
            {!paperData && (
                <div className="">
                    <form onSubmit={handleAddResearch} className="grid grid-cols-1 gap-5 justify-items-center mt-2">
                        <input type="text" ref={paperTitleDataRef} name="paperSearchTitle" placeholder="Paper Title" className="input input-bordered  w-full max-w-xs" />
                        <input type="text" ref={paperAuthorDataRef} name="paperSearchAuthor" placeholder="Paper Author" className="input input-bordered  w-full max-w-xs" />
                        <p className="text-left ">*Note: Minimum One Field Is Required</p>
                        <input type="submit" value={`${loading ? 'Searching...' : 'Search'}`} className="btn btn-primary  w-full max-w-xs" />
                    </form>
                </div>
            )}
            {/* <AddResearchCard data={searchResult}/> */}
            {searchResult && (
                <>
                    {searchResult.map((data) => (
                        <AddResearchCard key={data.id} id={data.id} title={data.title} authors={data.authors} conference={data.conference} url={data.url} />
                    ))}
                </>
            )}
            {/* {loading && <Loading/>} */}
        </div>
    );
};

export default AddResearch;
