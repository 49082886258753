import React from 'react';
import { toast } from 'react-toastify';

const UserRow = ({ user, userNo, refetch,handleRemoveUser }) => {
    const { _id, email, role } = user;

    const makeAdmin = () => {

        fetch(process.env.REACT_APP_API_URL+`/user/admin/${email}`, {
            method: 'PUT',
            headers: {
                'authorization': `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then(res => {

                if (res.status === 403) {
                    toast.error("failed to make an admin");
                }
                return res.json();
            })
            .then(data => {
                if (data.modifiedCount > 0) {
                    refetch();
                    toast.success(`Admin made successfully`);
                }

            })

    }
    return (
        <tr>
            <th>{userNo}</th>
            <td>{email}</td>
            {
                role === 'admin' ? <td> <button onClick={makeAdmin} disabled className="btn btn-xs btn-warning">Make Admin</button></td> : <td> <button onClick={makeAdmin} className="btn btn-xs btn-warning">Make Admin</button></td>
            }
            {/* <td>{role !== 'admin' && <button onClick={makeAdmin} className="btn btn-xs btn-warning">Make Admin</button>}</td> */}
            <td><button className="btn btn-xs btn-error " onClick={() => handleRemoveUser(_id)}>Remove User</button></td>
        </tr>
    );
};

export default UserRow;