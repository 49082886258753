import React from 'react';
import { useQuery } from 'react-query';
import Loading from '../Shared/Loading/Loading';
import Testimonial from './Testimonial';

const Testimonials = () => {
    //Loading testimonials Array api
    const { isLoading, error, data: testimonials, refetch } = useQuery('testimonialsData', () =>
        fetch(process.env.REACT_APP_API_URL + '/testimonials').then(res =>
            res.json()
        )
    )

    if (isLoading) {
        return <Loading></Loading>;
    }

    return (
        <div className='mt-10'>
            <h2 className='font-semibold text-2xl text-center'>Testimonials</h2>
            <div className='grid gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-10 w-10/12 mx-auto'>
                {
                    // events?.map(event => <Event key={event._id} showButton={true} event={event} handleJoinEvent={handleJoinEvent} handleRemoveEvent={handleRemoveEvent}></Event>)
                    testimonials?.map(testimonialInfo => <Testimonial key={testimonialInfo._id} testimonialInfo={testimonialInfo}></Testimonial>)
                }
            </div>
        </div>
    );
};

export default Testimonials;