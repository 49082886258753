import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';
import useAdmin from '../../hooks/useAdmin';

const ResearchComponent = ({ research, handleRemoveResearch }) => {
    const [user] = useAuthState(auth);
    const [admin] = useAdmin(user);
    const { _id, title, authors, conference, paperLink } = research;
    return (
        <div className="card w-100 my-4 bg-zinc-100 border rounded-lg">
            <div className="card-body">
                <h2 className="card-title">{authors}</h2>
                <p className='font-semibold  text-primary'>"{title}"</p>
                <p>{conference}</p>
                <p><button className="btn btn-primary rounded-md btn-sm"><a href={paperLink}>Paper Link</a></button> {admin && <button onClick={() => handleRemoveResearch(_id)} className="btn btn-outline btn-error btn-sm rounded-md mt-2">Delete This Paper</button>}</p>

            </div>
        </div>
    );
};

export default ResearchComponent;