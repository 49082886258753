import React from 'react';
import { CDN } from '../Shared/Utils';

const IMG_URL = "https://i.ibb.co/8b8dc48/13.jpg";
const cdnImgUrl = CDN(IMG_URL);

const Contact = () => {
    return (
        <div className='my-10 py-10 grid grid-cols-1 md:grid-cols-2'>
            <div className="">
                <img src={cdnImgUrl} className='w-10/12 mx-auto mb-10' alt="" />
            </div>
            <div className="w-10/12 mx-auto flex justify-center items-center">
                <div className="">
                    <h2 className='font-bold text-3xl text-center'>DIU - NLP & Machine Learning Reserach LAB</h2>
                    <h3 className='text-center text-xl'>Department of Computer Science and Engineering</h3>
                    <h3 className='text-center text-xl'>Daffodil International University</h3>
                    <p className='text-center'><span className='font-semibold'>Cell: </span>+8801673566566</p>
                    <p className='text-center'><span className='font-semibold'>Mail: </span>sheikh.cse@diu.edu.bd | nlp-ml.lab@diu.edu.bd</p>
                </div>

            </div>

        </div>
    );
};

export default Contact;